// eslint-disable-next-line
export const LOCATIONS_SEARCH_QUERY = `
  query LocationSearchQuery(
    $first: Int!, $offset: Int, $search: String, $town: String, $categorySlugs: String
  ) {
    locations(
      where: {
        town: $town
        search: $search
        categorySlugIn: $categorySlugs
        status: PUBLISH
        language: DEFAULT
        orderby: {
          order: ASC
          field: TITLE
        }
        offsetPagination: {
          size: $first
          offset: $offset
        }
      }
    ) {
      pageInfo {
        offsetPagination {
          hasMore
        }
      }
      nodes {
        title
        excerpt
        uri
        town
        address
        featuredImage {
          node {
            src: sourceUrl(size: NORMAL_POST)
            mediaDetails {
              width
              height
            }
          }
        }
      }
    }
  }
`
