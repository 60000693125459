import React from 'react'
import { List, ListItem } from '../ui'
import { PostPreviewWide } from '../posts'

export default function LocationList({ locations }) {
  return (
    <List mx={[0, -2]}>
      {locations.map(({ town, address, ...location }) => (
        <ListItem
          pb={4}
          px={[0, 2]}
          width={1}
          key={location.uri}
        >
          <PostPreviewWide
            {...location}
            meta={[town, address].filter((v) => !!v).join(', ')}
          />
        </ListItem>
      ))}
    </List>
  )
}
