import React, { memo } from 'react'
import { FlexCentered, Heading } from '../ui'
import LocationList from './LocationList'
import LocationListSkeleton from './LocationListSkeleton'

const LocationsSearchResults = ({ loading, error, locations }) => {
  if (loading || !locations) {
    return <LocationListSkeleton />
  }

  if (error || (locations && locations.length < 1)) {
    return (
      <FlexCentered>
        <Heading
          my={4}
          as="div"
        >
          Nincs találat.
        </Heading>
      </FlexCentered>
    )
  }

  return <LocationList locations={locations} />
}

export default memo(LocationsSearchResults)
